.read_only_personal_data_container {
  display: flex;
  justify-content: space-between;
  width: 100%;

  &-item {
    flex-basis: 33%;
    padding-bottom: 10px;

    &-label {
      font-style: normal;
      font-weight: 500;
      font-size: var(--standart);
      line-height: 20px;
      color: var(--additionalGrey);
      font-family: var(--IBM);
      margin-bottom: 10px;
    }
    &-content {
      font-style: normal;
      font-weight: 400;
      font-size: var(--standartLg);
      line-height: 20px;
      color: var(--black);
      font-family: var(--IBM);
      height: 1.4375em;
      padding: 16.5px 0;
    }
  }
}