$mobileMd: 600px;
$mobileSm: 450px;

.magazine_events {
  &-select_container {
    display: flex;
    padding: 0 0 14px;
    justify-content: space-between;
    align-items: center;

    & .input-wrapper {
      & .medium_container {
        max-width: 35%;
  
        @media screen and (max-width: $mobileMd) {
          max-width: 100%;
        }
      }
    }

    &-btn {
      height: 49px;

      @media screen and (max-width: $mobileSm) {
        width: 100%;
      }
    }
  };

  & .table_header_text {
    color: var(--black);
    font-family: var(--IBM);
    font-size: var(--standart);
    font-weight: 500;
    line-height: 1.1;
  }

  & .table_row_text {
    color: var(--black);
    font-family: var(--IBM);
    font-style: normal;
    font-weight: 400;
    font-size: var(--standart);
    line-height: 16px;
  }
};
