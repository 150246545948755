.analytics_panel {
  display: flex;
  justify-content: space-between;
  gap: 5px;

  .analytics_panel-block {
    height: 60px;
    border: 1px solid var(--borderLightGrey);
    box-shadow: 0px 0px 8px rgba(178, 182, 203, 0.32);
    display: flex;
    gap: 10px;
    padding: 0 10px;
    border-radius: 5px;
    align-items: center;
    justify-content: space-between;
    flex-basis: 22%;

    @media (max-width: 1715px) {
      padding: 5px 10px;
    }

    @media (max-width: 1300px) {
      flex-direction: column;
      justify-content: space-between;
      gap: 5px;
      padding-top: 10px;
      padding-bottom: 0px;
      height: initial;
    }

    &-wrapper {
      display: flex;
      align-items: center;
      gap: 5px;

      @media (max-width: 1300px) {
        padding-left: 20px;
      }
    }

    &-name {
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--standart);
      color: var(--black);
      line-height: 13px;
      overflow-wrap: break-word;

      @media (max-width: 1300px) {
        text-align: center;
      }
    }

    &-amount {
      font-size: var(--titleBlock);
    }

    &-percentage {
      font-size: var(--underInput);
    }
  }
}