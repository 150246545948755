.profile_container-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 7px;
  padding-bottom: 25px;
}
.profile_container-header-buttons {
  display: flex;
  gap: 20px;
}/*# sourceMappingURL=styles.css.map */