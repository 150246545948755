.profile_container {
  // margin-bottom: 150px;
  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 7px;
    padding-bottom: 25px;

    &-buttons {
      display: flex;
      gap: 20px;
    }
  }
}