.sidebar {
  background-color: var(--bgWhite);
  border-right: 1px solid var(--grey-transparent);
  min-height: 100vh;
  z-index: 1200;
}
.sidebar .sidebar-tablet-close-icon-button {
  display: none;
}
.sidebar-closed {
  min-width: var(--widthShortSideBar);
}
.sidebar-closed-logo {
  justify-content: center;
  padding-left: 10px;
}
.sidebar-opened {
  min-width: var(--widthFullSideBar);
}
.sidebar-opened-logo {
  justify-content: start;
  padding-left: 2px;
}
.sidebar-logo {
  height: var(--heighHeader);
  display: flex;
  align-items: center;
  cursor: pointer;
  -moz-column-gap: 10px;
       column-gap: 10px;
}
.sidebar-logo .logo-icon-sm {
  display: flex;
}
.sidebar-logo .logo-icon-sm svg {
  margin: 0;
  fill: var(--main);
  width: 50px;
  height: 53px;
}
.sidebar-logo .logo-label-icon {
  display: flex;
}
.sidebar-logo .logo-label-icon svg {
  margin: 0;
  fill: var(--main);
  width: 136px;
  height: 30px;
}
.sidebar-arrow-button {
  background: var(--bgWhite) !important;
  box-shadow: 0 0 4px rgba(76, 73, 126, 0.18);
  transform-origin: center center;
  width: var(--arrowBtn);
  height: var(--arrowBtn);
  z-index: 1300;
  top: -14px;
  border: 1px solid rgba(67, 57, 242, 0.4392156863);
}
.sidebar-list {
  border-right: 1px solid rgba(153, 155, 205, 0.16);
  box-shadow: none;
}
.sidebar-list.MuiAccordion-root {
  box-shadow: none;
}
.sidebar-list::before {
  height: 0 !important;
}
.sidebar-list-row:hover {
  border-right: 4px solid var(--main);
}
.sidebar-list-row:hover svg {
  fill: var(--black);
}
.sidebar-list-row:hover .sidebar-list-row-content-text {
  color: var(--black);
}
.sidebar-list-row-content {
  display: flex;
  align-items: center;
  gap: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.sidebar-list-row-content-icon {
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
}
.sidebar-list-row-content-icon svg {
  fill: var(--additionalGrey);
}
.sidebar .MuiAccordionSummary-content {
  margin: 0;
}
.sidebar .sidebar-list-row-content-text {
  font-family: var(--IBM);
  font-size: var(--standartLg);
  font-weight: 400;
  line-height: 24px;
}
.sidebar .sidebar-list-row .Mui-expanded .expended {
  color: var(--additionalGrey);
}
.sidebar .sidebar-list-row .Mui-expanded .expended:hover {
  color: var(--black);
}

@media screen and (max-width: 900px) {
  .sidebar .scrolled-sidebar {
    height: 87vh;
    max-height: 87vh;
  }
  .sidebar-list-row-content-icon {
    width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px;
  }
  .sidebar-list-row-content-icon svg {
    width: 32px;
    height: 32px;
  }
  .sidebar .sidebar-list-row-content-text {
    font-family: var(--IBM);
    font-size: var(--modalContext);
    font-weight: 400;
    line-height: 32px;
  }
  .sidebar-closed {
    min-width: 0;
    max-width: 0;
  }
  .sidebar-closed .sidebar-arrow-button {
    display: flex;
  }
  .sidebar-closed-logo {
    padding-left: 48px;
  }
  .sidebar-opened {
    min-width: 100%;
    position: fixed;
    height: 100%;
  }
  .sidebar-opened .sidebar-tablet-close-icon-button {
    display: block;
    position: fixed;
    top: 20px;
    right: 20px;
  }
  .sidebar-opened .sidebar-arrow-button {
    display: none !important;
  }
  .sidebar-opened-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 0;
    padding-bottom: 20px;
  }
  .sidebar-opened-logo .logo-icon-sm {
    padding-top: 0;
  }
  .sidebar-opened-logo .logo-icon-sm svg {
    height: 80px;
    width: 80px;
  }
  .sidebar-opened-logo .logo-label-icon {
    padding-top: 0;
    width: 165px;
  }
  .sidebar-opened-list {
    width: 100%;
  }
}/*# sourceMappingURL=style.css.map */