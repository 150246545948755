.analytics_panel {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}
.analytics_panel .analytics_panel-block {
  height: 60px;
  border: 1px solid var(--borderLightGrey);
  box-shadow: 0px 0px 8px rgba(178, 182, 203, 0.32);
  display: flex;
  gap: 10px;
  padding: 0 10px;
  border-radius: 5px;
  align-items: center;
  justify-content: space-between;
  flex-basis: 22%;
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
}
.analytics_panel .analytics_panel-block:hover {
  border: 1px solid rgba(67,57,242,0.7);
  box-shadow: 0px 0px 8px rgba(67,57,242,0.5);
  cursor: pointer;
}
@media (max-width: 1715px) {
  .analytics_panel .analytics_panel-block {
    padding: 5px 10px;
  }
}
@media (max-width: 1300px) {
  .analytics_panel .analytics_panel-block {
    flex-direction: column;
    justify-content: space-between;
    gap: 5px;
    padding-top: 10px;
    padding-bottom: 0px;
    height: initial;
  }
}
.analytics_panel .analytics_panel-block-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}
@media (max-width: 1300px) {
  .analytics_panel .analytics_panel-block-wrapper {
    padding-left: 20px;
  }
}
.analytics_panel .analytics_panel-block-name {
  font-family: var(--IBM);
  font-weight: 500;
  font-size: var(--standart);
  color: var(--black);
  line-height: 13px;
  overflow-wrap: break-word;
}
@media (max-width: 1300px) {
  .analytics_panel .analytics_panel-block-name {
    text-align: center;
  }
}
.analytics_panel .analytics_panel-block-amount {
  font-size: var(--titleBlock);
}
.analytics_panel .analytics_panel-block-percentage {
  font-size: var(--underInput);
}/*# sourceMappingURL=styles.css.map */