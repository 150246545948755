$mediumW: 1300px;
$smallW: 1050px;
$mediumH: 715px;
$smallH: 670px;

$mobileLg: 750px;
$mobileMd: 600px;
$mobileSm: 450px;
$mobileXSm: 350px;

body {
  font-family: var(--IBM);
  font-weight: 400;
  font-style: normal;
  font-size: var(--standart);
  line-height: 24px;
  color: var(--black);
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  #cleversite_clever_button {
   /* right: 5px !important;
    bottom: 5px !important;
    z-index: 1 !important;
    */
  }

  .link-black {
    color: var(--black);
    text-decoration: none;

    &:hover {
      color: var(--main);
    }
  }

  .scrolled-form {
    max-height: 66vh;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      /* chrome based */
      width: 0px; /* ширина scrollbar'a */
      background: transparent; /* опционально */
    }
  }

  .registration-scrolled-form {
    max-height: 75vh;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      /* chrome based */
      width: 0px; /* ширина scrollbar'a */
      background: transparent; /* опционально */
    }
  }

  .scrolled-tab_panel {
    max-height: 70vh;
    overflow-y: scroll;
    @media screen and (max-height: $mediumH) {
      max-height: 60vh;
    }
  }

  .scrolled-acts_panel {
    max-height: 78vh;
    overflow-y: scroll;
    @media screen and (max-height: $mediumH) {
      max-height: 70vh;
    }
  }

  .scrolled-table {
    max-height: 60vh;
    overflow-y: scroll;
  }

  .scrolled-sidebar {
    max-height: 83vh;
    overflow-y: scroll;
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      /* chrome based */
      width: 0; /* ширина scrollbar'a */
      background: transparent; /* опционально */
    }
  }

  .scrolled-page_content {
    max-height: 83vh;
    overflow-y: scroll;
  }

  .equipment-registry_dialog {
    & .MuiDialog-paper {
      max-width: 80vw;
      min-width: 60vw;
      height: 70vh;
      background-color: var(--white);
      padding: 0px 35px 35px 35px;
    }
  }

  .object_dialog {
    & .MuiDialog-paper {
      max-width: 90vw;
      height: 90vh;
      background-color: var(--white);
      padding: 35px 35px;
    }

    .main-content_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-flow: row-reverse;

      @media (max-width: 990px) {
        flex-direction: column;
      }
    }
  }

  .employee-modal {
    & .MuiDialog-paper {
      padding: 10px 35px;
    }
  }

  .form-section-label {
    font-family: var(--IBM);
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    color: var(--black);
    padding-left: 8px;
  }

  .big-title {
    color: var(--black);
    font-size: 24px;
    font-family: var(--IBM);
    font-weight: 500;
    line-height: 32px;
  }

  .task_dialog {
    & .MuiDialog-paper {
      max-width: 900px;
      height: 90vh;
      background-color: var(--white);
      padding: 35px 36px;
    }
  }

  .map_popup {
    background-color: white;
    position: fixed;
    width: 540px;
    top: 80px;
    right: 0;
    bottom: 0;
    overflow-y: scroll;
    box-shadow: 0 0 12px rgba(153, 155, 205, 0.27);
    border-radius: 2px 0 0 2px;
    padding: 24px 51px 0 24px;
    box-sizing: border-box;

    .main-content_wrapper {
      display: block;
    }
  }

  .map_popup,
  .object_dialog {
    .button_wrapper {
      display: flex;
      flex-direction: row;
      margin-top: 24px;
      justify-content: space-between;
      column-gap: 10px;
    }
    .pop_up_header {
      display: inline;
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--popupTitle);
      line-height: 28px;
      color: var(--black);
      margin-top: 45px;
    }
    .box {
      position: relative;
      overflow: hidden;
      width: 294px;
      height: 220px;
      margin-top: 22.4px;
    }

    .box .img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 300px;
      height: 300px;
      object-fit: cover;
    }
    .donut {
      margin-top: 31px;
      display: flex;
      align-items: center;
    }

    .responsible {
      margin-bottom: 16px;
      justify-content: space-between;
      border-top: 1px solid #e2e8f0;
    }
    .responsible_text {
      font-style: normal;
      font-family: var(--IBM);
      font-weight: 400;
      font-size: var(--standart);
      line-height: 24px;
      color: var(--light-black);
    }

    .data_text {
      font-style: normal;
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--standartLg);
      line-height: 24px;
      color: var(--black);
      margin: 16px 0;
    }

    .close_icon {
      position: absolute;
      top: 19px;
      right: 22px;
    }

    .data_small_headers {
      font-style: normal;
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--standartLg);
      line-height: 20px;
      color: var(--additionalGrey);
      border-bottom: 1px solid #e2e8f0;
    }
  }

  .task_dialog {
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 56px;
      padding-top: 52px;
      flex-wrap: wrap;
      gap: 10px;
    }

    &-title {
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--popupTitle);
      color: var(--black);
    }
    &-sub_title {
      display: flex;
      align-items: flex-end;
      gap: 10px;

      & p {
        font-family: var(--IBM);
        font-weight: 400;
        font-size: var(--standart);
        color: var(--black);
      }

      & span {
        font-weight: 500;
        font-size: var(--standartLg);
      }
    }

    &-head_buttons {
      display: flex;
      align-items: center;
      gap: 20px;
      flex-wrap: wrap;
    }

    .button_wrapper {
      display: flex;
      flex-direction: row;
      margin-top: 24px;
      justify-content: space-between;
      column-gap: 10px;
    }
    .pop_up_header {
      display: inline;
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--popupTitle);
      line-height: 28px;
      color: var(--black);
      margin-top: 45px;
    }
    .box {
      position: relative;
      overflow: hidden;
      width: 294px;
      height: 220px;
      margin-top: 22.4px;
    }

    .box .img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 300px;
      height: 300px;
      object-fit: cover;
    }
    .donut {
      margin-top: 31px;
      display: flex;
      align-items: center;
    }

    .responsible {
      margin-bottom: 16px;
      display: flex;
      justify-content: space-between;
    }
    .responsible_text {
      font-style: normal;
      font-family: var(--IBM);
      font-weight: 400;
      font-size: var(--standart);
      line-height: 24px;
      color: var(--light-black);
    }

    .data_text {
      font-style: normal;
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--standartLg);
      line-height: 24px;
      color: var(--black);
      margin: 16px 0;
    }

    .data_small_headers {
      font-style: normal;
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--standartLg);
      line-height: 20px;
      color: var(--additionalGrey);
      border-bottom: 1px solid #e2e8f0;
    }
  }

  .app_header {
    background: var(--bgHeader);
    border-bottom: 1px solid var(--grey-transparent);
    box-shadow: 0px 6px 8px -3px var(--grey-transparent);
  }

  .hidden {
    visibility: hidden;
    display: none;
    width: 0;
  }

  .template_page {
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      padding-top: 7px;
      padding-bottom: 25px;

      &-title {
        font-family: var(--IBM);
        font-size: var(--titlePage);
        font-weight: 500;
        color: var(--black);
        line-height: 48px;
        
        @media screen and (max-width: $mobileSm) {
          line-height: 38px;
          margin-bottom: 15px;
        }
      }

      &-buttons {
        display: flex;
        gap: 20px;
      }
    }
  }

  .title-page {
    font-family: var(--IBM);
    font-size: var(--titlePage);
    font-weight: 500;
    color: var(--black);
    line-height: 48px;

    @media screen and (max-width: $mobileSm) {
      line-height: 38px;
      margin-bottom: 15px;
    }
  }

  .subtitle-page {
    font-family: var(--IBM);
    font-size: var(--titleBlock);
    font-weight: 500;
    color: var(--additionalGrey);
  }

  .title-block {
    font-family: var(--IBM);
    font-size: var(--titleBlock);
    font-weight: 500;
    color: var(--black);
    line-height: 24px;
  }

  .label-block {
    font-family: var(--IBM);
    font-size: var(--standart);
    font-weight: 400;
    color: var(--black);
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .label-title {
    font-family: var(--IBM);
    font-size: var(--standartLg);
    font-weight: 600;
    color: var(--black);
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: 15px;
  }

  .default-label {
    font-family: var(--IBM);
    font-size: var(--standartLg);
    font-weight: 400;
    color: var(--black);
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .default-label-wrap {
    font-family: var(--IBM);
    font-size: var(--standartLg);
    font-weight: 400;
    color: var(--black);
    line-height: 24px;
    text-overflow: ellipsis;
    white-space: inherit;
  }

  .label-link {
    color: var(--main);
    font-size: var(--standartLg);
    text-decoration: none;
    text-transform: none;
    cursor: pointer;
  }

  .btn {
    font-family: var(--IBM);
    font-weight: 500;
    font-size: var(--standartLg);
    line-height: 24px;
    color: var(--white);
    min-width: 148px;
    text-transform: none;
    border-radius: 2px;
    padding: 12px 20px;

    @media screen and (max-width: 400px) {
      line-height: 18px;
    }

    &-cancel {
      background-color: var(--bgGrey);

      &:hover {
        background-color: var(--bgBlackGrey);
      }
    }

    &-outlined-primary {
      color: var(--main);
      border: 1px solid var(--main);
      background: var(--outlined);
      font-size: var(--standartLg);

      &:hover {
        border: 1px solid var(--main);
        box-shadow: 0px 1px 1px rgba(67, 57, 242, 0.11),
          0px 0px 0px 4px rgba(67, 57, 242, 0.12);
      }

      &.Mui-disabled {
        background: var(--lightGrey);
        border: 1px solid rgba(100, 116, 139, 0.2);
        color: #a9b4c5;
      }
    }

    &-outlined-grey {
      color: var(--additionalGrey);
      border: 1px solid var(--additionalGrey);
      background: var(--outlined);

      &:hover {
        box-shadow: 0px 1px 1px rgba(119, 131, 150, 0.11),
          0px 0px 0px 4px rgba(116, 135, 177, 0.16);
      }

      &.Mui-disabled {
        background: var(--lightGrey);
        border: 1px solid rgba(100, 116, 139, 0.2);
      }
    }

    &-primary-fill {
      background-color: var(--main);
      border: 1px solid var(--main);

      &:hover {
        background-color: var(--mainHover);
        border: 1px solid var(--mainHover);
      }

      &.Mui-disabled {
        background-color: var(--lightGrey);
        color: var(--lightGreyText);
        border: 1px solid var(--lightGrey);
      }
    }

    &-red-fill {
      background-color: var(--red);

      &:hover {
        background-color: var(--redHover);
      }

      &.Mui-disabled {
        background-color: var(--redDisabledBg);
        color: var(--redDisabledText);
      }
    }

    &-upload {
      color: var(--main);
      border-color: var(--main);
      font-size: var(--standart);
      border: 1px solid var(--bgTableHeader);
    }

    &-link,
    &-link-border {
      color: var(--main);
      font-size: var(--standartLg);
      text-decoration: none;
      text-transform: none;
    }

    &-link {
      padding: 0;
    }

    &-link-border {
      border: 1px solid var(--main);
      padding: 12px 19px;
    }

    &-remove {
      color: var(--red);
      border-color: var(--red);
      min-width: 148px;

      &:hover {
        background-color: var(--red);
        border-color: var(--red);
        color: var(--white);
      }
    }

    &-basket {
      padding: 5px;
    }

    &-basket-small {
      padding: 5px;
      min-width: inherit;
    }
  }

  .modal {
    & .border_bottom {
      border-bottom: 1px solid var(--borderLightGrey);
    }

    &-title-right {
      display: flex;
      justify-content: flex-end;
    }

    &-title-between {
      display: flex;
      justify-content: space-between;
    }

    &-title {
      font-family: var(--IBM);
      font-style: normal;
      font-weight: 500;
      font-size: var(--sectionTitle);
      color: var(--black);
    }

    &-text {
      font-family: var(--IBM);
      font-size: var(--modalContext);
      font-weight: 500;
      color: var(--black);
      line-height: 32px;
      text-align: center;
      max-width: 420px;
    }

    &-btn-field {
      padding: 56px 0;
      gap: 15px;

      &-center {
        justify-content: center;
      }

      &-right {
        justify-content: flex-end;
      }
    }
  }

  .select_columns {
    & .modal-title-between {
      padding-left: 34px;
      padding-right: 34px;
    }

    &-form {
      display: flex;
      flex-wrap: wrap;
      padding-top: 14px;

      & .checkbox {
        height: 25px;
      }
    }

    & .select_columns-btns {
      padding-right: 40px;
      padding-left: 40px;

      @media screen and (max-width: $mobileSm) {
        justify-content: center;
        padding-right: 15px;
        padding-left: 15px;
        padding-top: 15px;
        padding-bottom: 25px;
        gap: 0;
      }

      @media screen and (max-width: 400px) {
        & .btn {
          min-width: 131px;
          width: 131px;
        }
      }
    }
  }

  .modal_address {
    display: flex;
    align-items: center;
    justify-content: center;

    &-header_right {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-body {
      width: 530px;
      height: 300px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: var(--bgWhite);
      box-shadow: 0px 0px 8px rgba(178, 182, 203, 0.32);
      border-radius: 12px;
      padding: 26px 36px;

      &-title {
        font-family: var(--IBM);
        font-style: normal;
        font-weight: 500;
        font-size: var(--sectionTitle);
        color: var(--black);
      }

      &-form {
        padding-top: 42px;
      }

      &-buttons {
        display: flex;
        justify-content: flex-end;
      }
    }
  }

  & .MuiTabs-root .Mui-selected {
    color: var(--main);
  }

  & .MuiTabs-indicator {
    background-color: var(--main);
  }

  & .risk-category-container {
    & .MuiToggleButton-root {
      border-radius: 50% !important;
      height: 52px;
      width: 52px;

      &.extremely-high {
        border: 1px solid var(--red) !important;
        color: var(--red);

        &.Mui-selected,
        &:hover {
          background-color: var(--red-transparent);
        }
      }

      &.high {
        border: 1px solid var(--orange-soft) !important;
        color: var(--orange-soft);

        &.Mui-selected,
        &:hover {
          background-color: var(--orange-soft-transparent);
        }
      }

      &.significant {
        border: 1px solid var(--yellow) !important;
        color: var(--yellow);

        &.Mui-selected,
        &:hover {
          background-color: var(--yellow-transparent);
        }
      }

      &.medium {
        border: 1px solid var(--main) !important;
        color: var(--main);

        &.Mui-selected,
        &:hover {
          background-color: var(--main-transparent);
        }
      }

      &.moderate {
        border: 1px solid var(--lilac) !important;
        color: var(--lilac);

        &.Mui-selected,
        &:hover {
          background-color: var(--lilac-transparent);
        }
      }

      &.low {
        border: 1px solid var(--green) !important;
        column-gap: 10px;
        color: var(--green);

        &.Mui-selected,
        &:hover {
          background-color: var(--green-transparent);
        }
      }
    }
  }
  .small-title {
    font-family: var(--IBM);
    font-weight: 500;
    font-size: var(--standart);
    line-height: 20px;
    margin-bottom: 8px;
  }

  // .input-wrapper .error-text {
  //   font-family: var(--IBM);
  //   font-weight: 500;
  //   font-style: normal;
  //   font-size: var(--small);
  //   line-height: 24px;
  //   color: var(--red);
  //   text-overflow: ellipsis;
  //   white-space: nowrap;
  // }

  & .checkbox {
    height: 56px;
    &-label {
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--standart);
      line-height: 24px;
      color: var(--black);
    }
  }

  & .switcher {
    &-label {
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--standart);
      line-height: 24px;
      color: var(--black);
    }

    label {
      width: 150px;
    }
  }

  .input-wrapper .switcher-option .MuiFormControlLabel-label {
    font-family: var(--IBM);
    font-weight: 500;
    font-size: var(--standart);
    line-height: 24px;
    color: var(--black);
    gap: 8px;
  }

  .input-wrapper .date-picker label {
    width: 75%;
  }

  .input-wrapper label,
  .input-wrapper .MuiTypography-root,
  .input-wrapper .MuiInputBase-root {
    font-family: var(--IBM);
    font-weight: 400;
    font-size: var(--standartLg);
    line-height: 24px;
    width: 100%;
  }

  .input-wrapper .MuiTypography-root {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  .input-wrapper .wrap {
    white-space: pre-wrap;
  }

  .input-wrapper .error-text {
    font-family: var(--IBM);
    font-weight: 500;
    font-style: normal;
    font-size: var(--small);
    line-height: 15px;
    color: var(--red);
    text-overflow: ellipsis;
    white-space: initial;
    padding-top: 5px;
    width: 200px;
  }

  .input-wrapper {
    & .switcher {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      &-label {
        font-family: var(--IBM);
        font-weight: 500;
        font-size: var(--standart);
        line-height: 24px;
        color: var(--black);
        width: 150px;
      }

      label {
        width: 150px;
      }
    }
  }

  .input-wrapper .label {
    font-family: var(--IBM);
    font-weight: 500;
    font-size: var(--standart);
    line-height: 24px;
    color: var(--black);
    margin-bottom: 4px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .input-wrapper .under-input {
    font-family: var(--IBM);
    font-weight: 400;
    font-size: var(--underInput);
    line-height: 16px;
    color: var(--additionalGrey);
    margin-top: 4px;
    min-height: 32px;
  }

  .input-wrapper {
    & .sm {
      & .MuiTypography-root,
      & .MuiInputLabel-root,
      & input::placeholder,
      & input {
        color: var(--black);
        font-family: var(--IBM);
        font-size: 14px;
        font-weight: 500;
        line-height: 1.1;
      }
      & input::placeholder {
        opacity: 1;
      }
    }
    & .standart {
      margin-left: 0;
      & .MuiTypography-root,
      & .MuiInputLabel-root,
      & input::placeholder,
      & input {
        color: var(--black);
        font-family: var(--IBM);
        font-size: var(--standart);
        font-weight: 500;
        line-height: 1.1;
      }
      & input::placeholder {
        opacity: 1;
      }
    }
    & .standart-light {
      & .MuiTypography-root,
      & .MuiInputLabel-root,
      & input::placeholder,
      & input {
        color: var(--black);
        font-family: var(--IBM);
        font-size: var(--standart);
        font-weight: 400;
        line-height: 1.1;
      }
      & input::placeholder {
        opacity: 1;
      }
    }

    & .small-light {
      & .MuiTypography-root,
      & .MuiInputLabel-root,
      & input::placeholder,
      & input {
        color: var(--additionalGrey);
        font-family: var(--IBM);
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
      }
      & input::placeholder {
        opacity: 1;
      }
      & .MuiSelect-select {
        margin-left: 6px;
      }
    }

    & .select_in_built {
      & .MuiInputLabel-root {
        left: -6px;
      }
      & .MuiInputLabel-root.Mui-focused {
        display: none;
      }
      & .MuiFormLabel-filled.MuiInputLabel-shrink {
        display: none;
        position: absolute;
        transform: translate(14px, -9px) scale(0.75);
      }
      & .MuiOutlinedInput-root {
        & .MuiOutlinedInput-notchedOutline {
          border: none;
        }
        & .MuiSelect-select {
          height: 0;
          padding-top: 0px;
          padding-bottom: 0px;
          padding-left: 0;
        }
      }
    }
  }

  .MuiList-padding {
    padding-top: 0;
  }

  .input-wrapper .input-block {
    &-title {
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--standart);
      line-height: 20px;
      margin-bottom: 5px;
    }
  }

  .input-wrapper .input_block_label {
    margin-bottom: 8px;

    &-title {
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--standart);
      line-height: 20px;
    }

    &-helper {
      font-family: var(--IBM);
      font-weight: 400;
      font-size: var(--small);
      color: grey;
      line-height: 20px;
      text-align: center;
    }
  }

  .input-block {
    &-field {
      width: 100%;
      margin-bottom: 20px;
    }

    &-title {
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--standart);
      line-height: 20px;
      margin-bottom: 8px;
    }

    &-wrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border: 1px solid var(--borderLightGrey);
      padding: 0 16px;

      & > .input-wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }
  }

  .MuiInputLabel-root {
    max-width: calc(100% - 55px);
  }

  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      legend {
        max-width: 70%;
      }
    }
  }

  & .text_empty_page {
    font-family: var(--IBM);
    font-style: normal;
    font-weight: 400;
    font-size: var(--standart);
    color: var(--additionalGrey);
    margin-top: 7px;
  }
}
