.add-controller-container .add-controller-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 7px;
}
.add-controller-container .add-controller-header-form {
  display: flex;
  justify-content: space-between;
}
.add-controller-container .add-controller-header-form-buttons {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
}/*# sourceMappingURL=style.css.map */