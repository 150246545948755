.employee-select_container {
  display: flex;
  padding-left: 16px;
}
.employee .basic_form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
  gap: 15px;
  width: 30%;
  min-width: 400px;
}
@media (max-width: 600px) {
  .employee .basic_form  {
    width: 100%;
    min-width: 100%;
  }
}
@media (max-width: 1180px) {
  .employee .basic_form .contacts_form {
    width: 90vw;
  }
}/*# sourceMappingURL=style.css.map */