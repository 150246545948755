.controllers-container {
  .controllers-header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 7px;

    &-btns_field {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .icon-btn {
    height: max-content;
    display: flex;
    align-items: center;
    padding: 16px;
  }
}