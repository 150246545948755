.new_password_dialog {
  position: relative;
}
.new_password_dialog .MuiDialog-paper {
  background-color: var(--white);
  padding: 25px 40px;
  width: 45vw;
  margin: 0;
}
.new_password_dialog .new_password_dialog-close_icon {
  position: absolute;
  padding: 0;
  top: 19px;
  right: 22px;
}
.new_password_dialog.MuiDialogTitle-root {
  padding: 0;
}
.new_password_dialog .new_password_dialog-header {
  display: inline;
  font-family: var(--IBM);
  font-weight: 500;
  font-size: var(--popupTitle);
  line-height: 28px;
  color: var(--black);
  margin-top: 30px;
}
.new_password_dialog-submit_btn_wrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 30px;
}
.new_password_dialog .MuiAlert-root {
  padding: 18px;
  font-family: var(--IBM);
  font-style: normal;
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  display: flex;
  align-items: center;
}
.new_password_dialog .MuiAlert-root.MuiAlert-filledSuccess {
  background-color: var(--successBgSecondary);
  color: var(--successTextSecondary);
}
.new_password_dialog .MuiAlert-root.MuiAlert-filledError {
  background-color: var(--errorBg);
  color: var(--errorText);
}
.new_password_dialog .MuiAlert-root .MuiAlert-message {
  padding: 0;
}
.new_password_dialog .MuiAlert-root .MuiAlert-icon {
  font-size: var(--standartLg);
  height: var(--standartLg);
  width: var(--standartLg);
  padding: 0;
}
.new_password_dialog .MuiAlert-root svg {
  font-size: var(--standartLg);
  height: var(--standartLg);
  width: var(--standartLg);
}/*# sourceMappingURL=styles.css.map */