.table_field {
  width: 100%;
}
.table_field .MuiPaper-root {
  width: 100%;
  border-radius: 2px 2px;
  box-shadow: none;
  margin-bottom: 0;
}
.table_field .table_search_form {
  max-width: 398px;
  border-radius: 4px 4px;
  margin-bottom: 12px;
}

.table .sticky {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 3;
}
.table .table-head .sticky {
  z-index: 4;
}
.table .table-head .table-head-cell {
  color: var(--black);
  font-family: var(--IBM);
  font-weight: 500;
  font-size: var(--standart);
  background-color: var(--bgTableHeader);
  height: 80px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
}
.table .table-head-up_level {
  height: 40px;
  display: flex;
  align-items: center;
}
.table .table-head-up_level .standart {
  color: var(--black);
  font-family: var(--IBM);
  font-size: var(--standart);
  font-weight: 500;
}
.table-body .table-row-cell {
  color: var(--black);
  font-family: var(--IBM);
  font-weight: 400;
  font-size: var(--standart);
  line-height: 24px;
  padding-left: 20px;
}
.table-body .table-row-cell .avatar_block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
}
.table-body .table-row-cell .avatar_block:not(:last-child) {
  margin-bottom: 16px;
}
.table-body .table-row-cell-link {
  font-family: var(--IBM);
  font-style: normal;
  font-weight: 400;
  font-size: var(--standart);
  line-height: 20px;
  color: var(--main);
  cursor: pointer;
}
.table-body .MuiTypography-root {
  font-family: var(--IBM);
  font-weight: 400;
  font-size: var(--standart);
  line-height: 24px;
}
.table-body .point_and_text_cell {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 7.5px;
}
.table-body .point_and_text_cell-point {
  width: 10px;
  height: 10px;
  border-radius: 5px 5px;
}
.table-body .point_and_text_cell-text {
  font-family: var(--IBM);
  font-style: normal;
  font-weight: 400;
  font-size: var(--standart);
  line-height: 20px;
}
.table-body .small_cell-text {
  font-family: var(--IBM);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.table-body .bold {
  font-weight: 900;
}
.table-body .standart_lg {
  font-size: var(--standartLg);
}
.table-body .additional_grey {
  color: var(--additionalGrey);
}
.table-body .chip {
  width: 155px;
  height: 28px;
  color: var(--black);
  text-transform: uppercase;
  font-family: var(--IBM);
  font-style: normal;
  font-weight: 500;
  font-size: var(--standart);
  line-height: 20px;
}/*# sourceMappingURL=style.css.map */