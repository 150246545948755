:root {
  --heighHeader: 80px;

  // sideBar
  --widthShortSideBar: 96px;
  --widthFullSideBar: 316px;
  --arrowBtn: 28px;

  // font-size
  --small: 10px;
  --underInput: 12px;
  --standart: 14px;
  --standartLg: 16px;
  --titleBlock: 18px;
  --modalContext: 24px;
  --sectionTitle: 24px;
  --popupTitle: 24px;
  --titlePage: 32px;

  // screen-size-width
  --mdScreen: 1200px;
  --tabletWidth: 900px;
}