.notification-item {
  display: flex;
  padding: 10px 0;
  color: var(--text-black);
  font-size: 14px;
  font-family: var(--IBM);
  line-height: 24px;
}

.notification-item-name {
  display: inline;
  color: var(--text-black);
  font-size: 14px;
  font-family: var(--IBM);
  font-weight: 500;
  line-height: 24px;
}
.pushText {
  font-size:20px;
  font-family: var(--IBM);
  font-weight: 500;
}
.pushIcon {
  width:30px !important;
  height:30px !important;
  margin-right: 15px;
  color:var(--main)
}
.push-box-item{
  place-items: center;
  display: flex ;
  margin-bottom:10px;
}

.notification-item-date,
.notification-item-status {
  display: inline;
  display: flex;
  color: var(--text-black);
  font-size: 14px;
  font-family: var(--IBM);
  line-height: 24px;
}
.notification-item-detail {
  display: inline;
  display: flex;
  color: var(--text-black);
  font-size: 12px;
  font-family: var(--IBM);
  line-height: 24px;
}
.notification-item-date {
  font-weight: 500;
  font-size: 12px;
}

.notification-item-link {
  color: #4339f2;
  font-size: 12px !important;
  line-height: 18px !important;
  text-transform: none;
}

.paper-wrapper {
  padding: 20px;
  width: 440px;
  padding-bottom: 35px;
}

.mark-laber {
  color: #4339f2;
  font-size: 12px;
  text-transform: none;
  position: relative;
}
.box {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  border-top: 1px solid #e2e8f0;
}
.info {
  width: 200px;
  text-align: center;
  margin-top: 12px;
}
