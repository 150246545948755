.table_field {
  width: 100%;
  & .MuiPaper-root {
    width: 100%;
    border-radius: 2px 2px;
    box-shadow: none;
    margin-bottom: 0;
  }

  & .table_search_form {
    max-width: 398px;
    border-radius: 4px 4px;
    margin-bottom: 12px;
  }
}
.table {
  .sticky {
    position: sticky;
    left: 0;
    z-index: 3
  };

  .table-head {
    .sticky {
      z-index: 4;
    }
    .table-head-cell {
      color: var(--black);
      font-family: var(--IBM);
      font-weight: 500;
      font-size: var(--standart);
      background-color: var(--bgTableHeader);
      height: 50px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 20px;
    }

    &-up_level {
      height: 40px;
      display: flex;
      align-items: center;

      & .standart {
        color: var(--black);
        font-family: var(--IBM);
        font-size: var(--standart);
        font-weight: 500;
      }
    } 
  }

  &-body {
    .table-row-cell {
      color: var(--black);
      font-family: var(--IBM);
      font-weight: 400;
      font-size: var(--standart);
      line-height: 24px;
      padding-left: 20px;

      & .avatar_block {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 8px;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
      }
    }

    .table-row-cell-link {
      font-family: var(--IBM);
      font-style: normal;
      font-weight: 400;
      font-size: var(--standart);
      line-height: 20px;
      color: var(--main);
      cursor: pointer;
    }

    & .MuiTypography-root {
      font-family: var(--IBM);
      font-weight: 400;
      font-size: var(--standart);
      line-height: 24px;
    }

    & .point_and_text_cell {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 7.5px;

      &-point {
        width: 10px;
        height: 10px;
        border-radius: 5px 5px;
      };

      &-text {
        font-family: var(--IBM);
        font-style: normal;
        font-weight: 400;
        font-size: var(--standart);
        line-height: 20px;
      }
    };

    & .small_cell-text {
      font-family: var(--IBM);
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    & .bold {
      font-weight: 900;
    }

    & .standart_lg {
      font-size: var(--standartLg);
    }

    & .additional_grey {
      color: var(--additionalGrey);
    }

    & .chip {
      width: 155px;
      height: 28px;
      color: var(--black);
      text-transform: uppercase;
      font-family: var(--IBM);
      font-style: normal;
      font-weight: 500;
      font-size: var(--standart);
      line-height: 20px;
    }
  };
}
