.balloon_content {
  padding: 0 14px;
}
.balloon_content-list {
  list-style-type: none;
  padding-left: 0;
}
.balloon_content-list-item {
  list-style: none;
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: 400;
  cursor: pointer;
}
.balloon_content-list-item .link-black {
  color: var(--black);
  text-decoration: none;
  margin: 0;
}
.balloon_content-list-item .link-black:hover {
  color: var(--main);
}
.balloon_content-list-item .link-black .link_point {
  display: inline-block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 5px;
}

.balloon_footer-text {
  list-style: none;
  font-family: var(--IBM);
  color: var(--main);
  font-size: var(--standart);
  font-weight: bold;
  text-decoration: none;
  text-transform: none;
  text-align: end;
  cursor: pointer;
}/*# sourceMappingURL=style.css.map */