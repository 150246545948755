.page-content {
  padding-left: 16px;
  padding-right: 16px;
  height: 100vh;
}
.page-content .breadcrumbs {
  padding: 20px 0 7px;
  font-family: var(--IBM);
  font-size: var(--standart);
  color: var(--additionalGrey);
}
.page-content .breadcrumbs-root {
  font-family: var(--IBM);
  font-size: var(--standart);
  color: var(--additionalGrey);
}
.page-content .breadcrumbs-current {
  font-family: var(--IBM);
  font-size: var(--standart);
  color: var(--main);
}
.page-content .breadcrumbs-link {
  font-family: var(--IBM);
  font-size: var(--standart);
  color: var(--additionalGrey);
  cursor: pointer;
}
.page-content .breadcrumbs-link:hover {
  color: var(--black);
}/*# sourceMappingURL=style.css.map */