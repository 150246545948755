.employee {
  &-select_container {
    display: flex;
    padding-left: 16px;
  };

  & .basic_form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    gap: 15px;
    width: 100%;

    @media (max-width: 1180px) {
      & .contacts_form {
        flex-basis: 48%;
      }
    }
  }
};