$mediumW: 1300px;
$smallW: 1050px;
$smallH: 670px;

$mobileLg: 750px;
$mobileMd: 600px;
$mobileSm: 450px;
$mobileXSm: 350px;

.login_wrapper {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  box-sizing: border-box;
  box-shadow: 4px 3px 16px 1px rgba(0,0,0,0.29);
  height: 100vh;

  @media screen and (max-height: $smallH) {
    height: inherit;
  }

  @media screen and (max-width: $smallW) {
    box-shadow: none;
  }

  .login-title-auth {
    font-style: normal;
    font-size: 56px;
    font-weight: 400;
    line-height: 64px;
    font-family: var(--IBM);

    @media screen and (max-width: $smallW) {
      font-size: 43px;
    }

    @media screen and (max-width: $mobileXSm) {
      font-size: 32px;
    }
  };

  .login-title {
    font-style: normal;
    font-weight: 400;
    font-size: 40px;
    line-height: 52px;
    font-family: var(--IBM);
  };

  .default-label-text {
    font-style: normal;
    font-family: var(--IBM);
    line-height: 24px;
    font-weight: 400;
    font-size: var(--standartLg);
    &.sm {
      font-size: 12px;
      line-height: 18px;
    };

    &.strong {
      font-weight: 500;
    };
  };

  .login-form {
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    row-gap: 50px;
  };

  & .left_block {
    flex-basis: 43%;
    padding: 10% 60px 40px;
    box-sizing: border-box;
    background: #4339F2;
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10%;

    @media (max-width: $mobileLg) {
      display: none;
    }

    &-logo {
      margin: 0 auto;

      & img {
        width: 180px;
      };
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 15%;

      &-logo_text_field {
        display: flex;
        align-items: center;
        height: 30px;
      }

      & svg {
        width: 100%;
        height: 100%;
      }

      &-text {
        font-family: var(--IBM);
        font-size: 35px;
        font-weight: 500;
        line-height: 45px;
        color: var(--white);
        font-style: normal;
        text-align: center;

        @media screen and (max-width: $mediumW) {
          font-size: 25px;
          line-height: 30px;
        }

        @media screen and (max-width: $smallW) {
          font-size: 21px;
          line-height: 25px;
        }

        @media screen and (max-height: $smallH) {
          font-size: 21px;
          line-height: 25px;
        }
      }
    }
  }

  & .right_block {
    display: flex;
    margin: 0 auto;
    flex-direction: column;
    padding: 10% 0 5%;
    min-width: 25%;
    max-width: 50%;

    @media screen and (max-width: $mobileMd) {
      max-width: 80%;
    }

    & .first_step {
      &-btn_next {
        margin-top: 30px;
        margin-bottom: 30px;

        @media screen and (max-width: $mobileXSm) {
          margin-top: 10px;
        }
      }
    }

    & .btn-link {
      @media screen and (max-width: $mobileMd) {
        font-size: 14px;
        line-height: 20px;
      } 

      @media screen and (max-width: $mobileXSm) {
        font-size: 12px;
        line-height: 12px;
      }
    }

    & .confirm_conditions {
      margin: 16px 0;

      & .MuiFormControlLabel-label {
        @media screen and (max-width: $mobileMd) {
          font-size: 13px;
        }

        @media screen and (max-width: $mobileSm) {
          font-size: 12px;
        }

        @media screen and (max-width: $mobileXSm) {
          line-height: 12px;
        }
      }
    }
  }
}