.profile_contact {
  display: flex;
  align-items: stretch;
  margin-bottom: 40px;
  gap: 40px;
}
.profile_contact-contacts {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 0;
}
.profile_contact-contacts .profile_contact-fio {
  font-family: var(--IBM);
  font-style: normal;
  font-weight: 500;
  font-size: var(--sectionTitle);
  line-height: 32px;
  color: var(--black);
  margin-bottom: 16px;
}
.profile_contact-contacts .profile_contact-job_employments_block {
  font-family: var(--IBM);
  font-style: normal;
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  color: var(--additionalGrey);
  margin-bottom: 35px;
}
.profile_contact-contacts .profile_contact-connection {
  display: flex;
  gap: 40px;
}
.profile_contact-contacts .profile_contact-connection .profile_contact-connection-text {
  font-family: var(--IBM);
  font-style: normal;
  font-weight: 400;
  font-size: var(--standartLg);
  line-height: 24px;
  color: #212121;
}
@media screen and (max-width: 1050px) {
  .profile_contact-contacts .profile_contact-fio {
    margin-bottom: 0;
  }
  .profile_contact-contacts .profile_contact-job_employments_block {
    margin-bottom: 10px;
  }
  .profile_contact-contacts .profile_contact-connection {
    gap: 10px;
    flex-wrap: wrap;
  }
}/*# sourceMappingURL=styles.css.map */