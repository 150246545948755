.page-content {
  padding-left: 16px;
  padding-right: 16px;
  height: 100vh;
  .breadcrumbs {
    padding: 20px 0 7px;
    font-family: var(--IBM);
    font-size: var(--standart);
    color: var(--additionalGrey);

    &-root {
      font-family: var(--IBM);
      font-size: var(--standart);
      color: var(--additionalGrey);
    }

    &-current {
      font-family: var(--IBM);
      font-size: var(--standart);
      color: var(--main);
    }

    &-link {
      font-family: var(--IBM);
      font-size: var(--standart);
      color: var(--additionalGrey);
      cursor: pointer;
      &:hover {
        color: var(--black);
      }
    }
  }
}
